.main{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #F4F4F4;
    flex-direction: column;
    padding-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    .logowrap {
        display:flex;
        align-items: center;
        // align-items: flex-end;
        background-color:#fff;
        padding-top:16px;
        padding-bottom:20px;
        padding-left:16px;
        padding-right:16px
    }
    .name{
        font-size: 4rem;
        font-weight: 500;
        font-family:'Times New Roman', Times, serif;
        margin-top: 20px;
        text-align: center;
    }
    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #fff;
        // height: 100%;
        margin-top: 4rem;
        border-radius: 2rem;
        flex: 1;
    }
    .inputwarp {
        position:relative;
        padding-left:16px;
        padding-right:16px;
        padding-top:45px;
        background:#fff;
        // padding-bottom:16px;
        
    }
    input{
        width: 100%;
        height: 5.2rem;
        border-style: none;
        border-radius: 0;
        // font-size: 7rem;
        font-size: 3.4rem;
        font-weight: bold;
        padding-left: 2.6rem;
        // padding-bottom: 10px;
        background-image: url('../public/RMB.png');
        background-repeat: no-repeat;
        background-size: 17px;
        background-position-y: 50%;
        color: #333;
        border-bottom: 1px solid #DFDFDF ;
        background-color: rgba(0,0,0,0);
        font-family: Arial, Helvetica, sans-serif;
    }
    input:focus{
        outline: none;
        // border: 1px solid #18c2cd;
    }
    //添加备注
    .notewrap {
        display:flex;
        align-items:center;
        margin-top:15px;
        margin-bottom: 15px;
    }
    .note_text {
        font-size:14px;
        overflow:hidden;
        flex:1;
        color: #999999 ;
    }
    .update_note{
        font-size:14px;
        margin-left:auto;
        text-align:right;
        color:#F24E7D;
        width:100px;
        display:block
    }
    .add_note {
        font-size:14px;
        margin-left:auto;
        text-align:right;
        margin-top:15px;
        margin-bottom: 15px;
        color:#F24E7D
    }
    table{
        width: 100%;
        // height: 40%;
        // height: 35%;
        position: absolute;
        // bottom: 0;
        bottom: 15px;
        border-collapse:collapse;
        font-size: 4rem;
        font-weight: normal;
        font-family: Arial, Helvetica, sans-serif;
        // font-weight: 600;
        background-color: #fff;
        // margin-bottom: 10px;
        td{
            // outline: none;
            width: 25%;
            height: 56px;
            color: #333333;
            // font-size: 4rem;
            font-size: 2.2rem;
            border: 1px solid #DFDFDF;
            text-align: center;
        }
        .first_line {
            // font-size:2.5rem;
            font-size:1.2rem;
            padding-top:10px;
            padding-bottom:10px;
            font-weight:normal;
            height:30px;
            color:#999;
        }
    }
    .del{
        background-image: url('../public/del@2x.png');
        background-repeat: no-repeat;
        // background-position-x: 17px;
        background-position: 50% 50%;
        background-size:25%;
        height: 25%;
        // padding:3.5rem 0
    }
    .pay{ 
        background-color: #F24E7D ;
        // background-color: #ecc514;
        color: #fff;
        // writing-mode: vertical-lr;
        letter-spacing: 1rem;
        // padding-left: 5px;
    }
    .order_info{
        width: 80%;
        font-size: 1.4rem;
        margin-top: 2rem;
        color: #464545;
        p{
            line-height: 3rem;
        }
    }
    .offPage{
        width: 70%;
        height: 20%;
        background: #fff;
        margin: 0 auto;
        margin-top: 50%;
        font-size: 2.5rem;
        text-align: center;
        padding-top: 6%;
        border-radius: 1rem;
        p{
            text-align: left;
            padding-left: 2rem;
            font-size: 2.8rem;
            font-weight: 500;
            margin-bottom: 2rem;
            }
    }
    .clear {
        position: absolute;
        right: 20px;
        top:5.8rem
        // top:53px
    }
    .lineWrap {
        background-color: #fff;
        padding: 0 15px;
    }
    .line {
        // padding: 0 15px;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid #dfdfdf;
        // background-color: #DFDFDF;
        // margin: 0 15px;
    }
}
.modalHeader {
    text-align: center;
    color: #333333;
    font-size: 16px;
    // margin-bottom: 13px;
}
.modal_input {
    width: 100%;
    margin: 13px 0;
    height: 40px;
    border:1px solid #DFDFDF;
    text-indent:5px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
}
.modal_footer {
    display: flex;;
    span {
        display: block;
        font-size: 16px;
        flex:1;
        justify-content: center;
        display: flex;
    }
    
}
.success {
    width: 60px;
    height: 60px;
}
.success_text {
    color: #1AAC19;
    font-size: 16px;
    margin-top: 5px;
}
.fail_text {
    color: #EB4255;
    font-size: 16px;
    margin-top: 5px;
}
.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.modal_line {
    // padding: 0 15px;
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #DFDFDF;
    left: 0;
    // margin: 0 15px;
}
.loading {
    position: fixed; /* 确保该元素相对于最近的定位了的祖先元素进行定位 */
    width: 100%;
    height: 100%;
    top: 0;
    img {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%); 
    }
    p {
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #F24E7D;
        font-size:12px
    }
}